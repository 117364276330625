import { copyConstants } from './copyConstants';

export type NavItem = {
  name: string;
  href: string;
  icon?: string;
  alt?: string;
  bio?: string;
  new?: boolean;
};

export type NavSection = {
  title?: string;
  paths: NavItem[];
};

export type SubMenuStructure = {
  submenu: string;
  href: string;
  external?: boolean;
  onlineBank?: boolean;
  button?: boolean;
  webflow?: boolean;
  structure?: NavSection[];
};

export type NavHierarchy = Record<string, SubMenuStructure>;

export type NavLink = {
  icon?: string;
  text: string;
  href: string;
  children?: NavLink[];
};

export type NavLinkSection = {
  heading: string;
  links: NavLink[];
};

type SiteNavigation = {
  [key in 'pca' | 'bca']: {
    intro: string[];
    footerLinks: { [key in 'one' | 'two' | 'three' | 'four']: NavLink[] };
    productLinks: NavLink[];
    linkSections: NavLinkSection[];
  };
} & {
  [key in 'topRight']: NavLink[];
};

export const headerPrimary: NavHierarchy = {
  'Current accounts': {
    submenu: 'currentAccount',
    href: '#',
    structure: [
      {
        title: 'Accounts',
        paths: [
          {
            name: 'Personal account',
            href: '/current-account/',
            bio: 'Our award winning current account'
          },
          {
            name: 'Joint account',
            href: '/current-account/joint-bank-account/',
            bio: 'Simplify shared spending'
          },
          {
            name: 'Euro account',
            href: '/current-account/euro-bank-account/',
            bio: 'A euro bank account in the UK'
          },
          {
            name: 'Starling Kite',
            href: '/current-account/kite-debit-card-for-kids/',
            bio: 'A debit card for your child'
          },
          {
            name: 'Teen account',
            href: '/current-account/bank-account-for-teenagers/',
            bio: 'Better banking for 16 and 17 year olds'
          }
        ]
      },
      {
        title: 'Features',
        paths: [
          { name: 'Virtual cards', href: '/features/virtual-cards/' },
          { name: 'Online banking', href: '/online-banking/' },
          { name: 'Mobile cheque deposits', href: '/features/deposit-cheques-from-mobile/' },
          { name: 'Connected cards', href: '/features/connected-shopping-card/' },
          { name: 'No fees travel', href: '/travel/' },
          { name: 'Bills Manager', href: '/features/bills-manager/' },
          { name: 'Personal finance marketplace', href: '/current-account/personal-finance-marketplace/' },
          { name: 'Browse all app features', href: '/features/' }
        ]
      },
      {
        title: 'The essentials',
        paths: [
          { name: 'How to apply for an account', href: '/open-bank-account-online/' },
          { name: 'Compare to other banks', href: '/current-account/compare-best-bank-accounts/' },
          { name: 'Security and fraud', href: '/mobile-banking-security/' },
          {
            name: 'Our fees, rates and charges',
            href: '/our-fees-rates-and-charges/'
          },
          { name: 'Switch to Starling', href: '/current-account/switch/' }
        ]
      },
      {
        title: 'Resources & support',
        paths: [
          { name: 'Budget planner', href: '/current-account/tools/budget-planner/' },
          { name: 'Cost of living', href: '/resources/tools/cost-of-living/' },
          { name: 'Currency converter', href: '/resources/tools/currency-calculator/' },
          { name: 'In-app help centre', href: '/contact/in-app-help-centre/' },
          { name: 'Customer support hub', href: '/help/' }
        ]
      }
    ]
  },
  'Business banking': {
    submenu: 'businessBanking',
    href: '#',
    structure: [
      {
        title: 'Accounts',
        paths: [
          {
            name: 'Business account',
            href: '/business-account/',
            bio: 'For limited companies and other registered businesses'
          },
          {
            name: 'Sole trader account',
            href: '/sole-trader-bank-account/',
            bio: 'Whether you’re self-employed or side-hustling'
          },
          {
            name: 'Multi-currency accounts',
            href: '/business-account/multi-currency/',
            bio: 'US dollar and euro bank accounts currently available for UK businesses'
          }
        ]
      },
      {
        title: 'Features',
        paths: [
          {
            name: 'Business Toolkit',
            href: '/business-account/sme-business-toolkit/',
            bio: 'Accounting and bookkeeping for your business account'
          },
          { name: 'Online banking', href: '/online-banking/' },
          { name: 'Mobile cheque deposits', href: '/features/deposit-cheques-from-mobile/' },
          { name: 'Bills Manager', href: '/features/bills-manager/' },
          { name: 'Business spending insights', href: '/features/business-spending-insights/' },
          { name: 'Business marketplace', href: '/business-account/marketplace-for-businesses/' },
          { name: 'Browse all app features', href: '/features/' }
        ]
      },
      {
        title: 'The essentials',
        paths: [
          { name: 'How to apply for an account', href: '/open-bank-account-online/' },
          { name: 'Business account eligibility', href: '/business-account/eligibility/' },
          { name: 'Compare to other banks', href: '/business-account/compare-business-bank-accounts/' },
          { name: 'Security and fraud', href: '/mobile-banking-security/' },
          {
            name: 'Business fees, rates and charges',
            href: '/our-fees-rates-and-charges/'
          },
          { name: 'Switch to Starling', href: '/business-account/switch-business-account/' }
        ]
      },
      {
        title: 'Resources & support',
        paths: [
          {
            name: 'Latest business guide',
            href: '/resources/business-guides/basis-period-reform-rules-and-changes/'
          },
          { name: 'Browse free business guides', href: '/resources/business-guides/' },
          { name: 'Business Class', href: '/resources/business/business-webinars/' },
          { name: 'In-app help centre', href: '/contact/in-app-help-centre/' },
          {
            name: 'Coronavirus government support',
            href: '/resources/business/covid19-tool-to-find-out-how-gov-supports-my-business/'
          }
        ]
      }
    ]
  },

  Savings: {
    submenu: 'savings',
    href: '#',
    structure: [
      {
        title: 'Personal Savings',
        paths: [
          {
            name: 'Easy Saver',
            href: '/savings/easy-saver/',
            bio: 'Add and withdraw as often as you like, with no minimum deposit and no penalties.'
          },
          {
            name: 'Fixed Saver',
            href: '/savings/fixed-rate-savings/',
            bio: 'Set money aside for a year in just a few taps.'
          }
        ]
      },
      {
        title: 'Business Savings',
        paths: [
          {
            name: 'Business Fixed Saver',
            href: '/business-account/business-fixed-rate-savings/'
          }
        ]
      }
    ]
  },
  'Overdrafts & Loans': {
    submenu: 'borrowing',
    href: '#',
    structure: [
      {
        title: 'Personal lending',
        paths: [
          {
            name: 'Personal overdraft',
            href: '/current-account/overdraft/',
            bio: 'A little extra support from time to time'
          },
          {
            name: 'Overdraft cost calculator',
            href: '/current-account/overdraft/overdraft-cost-calculator/'
          },
          {
            name: 'Overdraft eligibility tool',
            href: '/current-account/overdraft/overdraft-eligibility-tool/'
          }
        ]
      },
      {
        title: 'Business lending',
        paths: [
          {
            name: 'Business overdraft',
            href: '/business-account/overdraft/',
            bio: 'Built to give your business a cashflow boost'
          },
          {
            name: 'Business loan',
            href: '/loans/business-loan/',
            bio: 'A fair and flexible business loan'
          }
        ]
      },
      {
        title: 'Business coronavirus support',
        paths: [
          {
            name: 'Repaying your Bounce Back Loan',
            href: '/business-account/business-bounce-back-loan-scheme/#repay'
          },
          {
            name: 'Pay As You Grow options',
            href: '/business-account/business-bounce-back-loan-scheme/pay-as-you-grow-bounce-back-repayment-calculator/'
          }
        ]
      }
    ]
  },
  'Money transfers': {
    submenu: 'moneyTransfers',
    href: '#',
    structure: [
      {
        paths: [
          {
            name: 'Send money abroad',
            href: '/send-money-abroad/',
            bio: `Fast and secure money transfers to bank accounts in ${copyConstants.sendMoneyCountryNumber} countries worldwide`
          }
        ]
      },
      {
        title: 'Top currencies',
        paths: [
          {
            name: 'Euro transfers',
            href: '/send-money-abroad/send-money-to-europe/',
            icon: 'EUR',
            alt: 'Flag of Europe'
          },
          {
            name: 'US dollar transfers',
            href: '/send-money-abroad/send-money-to-usa/',
            icon: 'USD',
            alt: 'Flag of the United States'
          },
          {
            name: 'Rand transfers',
            href: '/send-money-abroad/send-money-to-south-africa/',
            icon: 'ZAR',
            alt: 'Flag of South Africa'
          },
          {
            name: 'Zloty transfers',
            href: '/send-money-abroad/send-money-to-poland/',
            icon: 'PLN',
            alt: 'Flag of Poland'
          }
        ]
      }
    ]
  }
};

export const headerSecondary: NavHierarchy = {
  About: {
    submenu: 'about',
    href: '#',
    structure: [
      {
        paths: [
          {
            name: 'About us',
            href: '/about/',
            bio: 'Our story - and why we’re different'
          },
          {
            name: 'Sustainable banking',
            href: '/about/sustainable-banking/',
            bio: 'What we’re doing to help the planet'
          },
          {
            name: 'Refer a friend',
            href: '/refer-a-friend/',
            bio: 'Refer a friend to Starling'
          }
        ]
      }
    ]
  },
  'Blog & News': {
    submenu: 'latestNews',
    href: '#',
    structure: [
      {
        paths: [
          {
            name: 'Noteworthy',
            href: '/blog/',
            bio: 'Articles on money mastery and real-life money truths'
          },
          {
            name: 'Newsroom',
            href: '/media/',
            bio: 'The latest press releases'
          }
        ]
      }
    ]
  },
  Careers: {
    submenu: 'careers',
    href: '/careers/'
  },
  Help: {
    submenu: 'help',
    href: '/help/'
  },
  'Log in to Online Banking': {
    submenu: 'online-banking',
    external: true,
    href: 'https://app.starlingbank.com/',
    onlineBank: true
  },
  'Apply now': {
    submenu: 'app',
    href: '/apply/',
    button: true,
    webflow: true
  }
};

export const footer = {
  Company: {
    paths: [
      {
        name: 'About Starling',
        href: '/about/'
      },
      {
        name: 'Annual Report & Pillar 3',
        href: '/investors/'
      },
      {
        name: 'Careers',
        href: '/careers/'
      },
      {
        name: 'Charity Partnerships',
        href: '/about/partnerships/charity-partnerships/'
      },
      {
        name: 'Corporate Governance Statement',
        href: '/about/corporate-governance-statement/'
      },
      {
        name: 'Ethics statement',
        href: '/about/ethics-statement/'
      },
      {
        name: 'Gender equality',
        href: '/about/gender-equality/'
      },
      {
        name: 'If Not Now, When? Initiative',
        href: '/docs/reports-research/Starling-IfNotNowWhen.pdf'
      },
      {
        name: 'Internal Audit Charter',
        href: '/about/starling-bank-internal-audit-charter/'
      },
      {
        name: 'Newsroom',
        href: '/media/'
      },
      {
        name: 'Refer a friend scheme',
        href: '/refer-a-friend/'
      },
      {
        name: 'Tax strategy',
        href: '/about/our-tax-strategy/'
      },
      {
        name: 'Women in Finance Charter',
        href: '/blog/women-in-finance-charter-update/'
      }
    ]
  },
  Legal: {
    paths: [
      {
        name: 'All legal documents',
        href: '/legal/'
      },
      {
        name: 'FSCS Protection',
        href: '/legal/account/fscs-protection/'
      },
      {
        name: 'Privacy Notice',
        href: '/legal/privacy-notice/'
      },
      {
        name: 'Modern Slavery Statement',
        href: '/legal/modern-slavery-statement/'
      },
      {
        name: 'Carbon Reduction Plan',
        href: '/about/carbon-reduction-plan/'
      }
    ]
  },
  'Customer support': {
    paths: [
      {
        name: 'Cost of living',
        href: '/resources/tools/cost-of-living/'
      },
      {
        name: 'APP Scams Code',
        href: '/mobile-banking-security/fraud/'
      },
      {
        name: 'Banking glossary',
        href: '/resources/banking/banking-terms-and-definitions/'
      },
      {
        name: 'Customer support hub',
        href: '/help/'
      },
      {
        name: 'Fees, rates and charges',
        href: '/our-fees-rates-and-charges/'
      },
      {
        name: 'Help centre',
        href: 'https://help.starlingbank.com/',
        external: true
      },
      {
        name: 'How to contact us',
        href: '/contact/'
      },
      {
        name: 'Responsible Disclosure',
        href: '/security/disclosure/'
      },
      {
        name: 'Security and fraud',
        href: '/mobile-banking-security/'
      },
      {
        name: 'Service status',
        href: 'https://starlingbank.statuspage.io/',
        external: true,
        icon: 'status-nav'
      },
      {
        name: 'Website accessibility',
        href: '/website-accessibility/'
      },
      {
        name: 'Social media community guidelines',
        href: '/about/starling-bank-social-media-community-guidelines/'
      }
    ]
  },
  'Partner with us': {
    paths: [
      {
        name: 'Accountant referral programme',
        href: '/referral/accountant/'
      },
      {
        name: 'B2B Banking Services',
        href: '/banking-services/'
      },
      {
        name: 'Developers',
        href: 'https://developer.starlingbank.com/',
        external: true
      },
      {
        name: 'Engine by Starling',
        href: 'https://www.enginebystarling.com/',
        external: true
      },
      {
        name: 'Lending Partnership Programme',
        href: '/referral/lending-partnership-programme/'
      }
    ]
  }
};

export const socials = {
  paths: [
    {
      href: 'https://www.facebook.com/StarlingBankUK',
      aria: 'on Facebook',
      icon: 'icon-facebook-squared'
    },
    {
      href: 'https://twitter.com/starlingbank',
      aria: 'on Twitter',
      icon: 'icon-x'
    },
    {
      href: 'https://www.threads.net/@StarlingBank',
      aria: 'on Threads',
      icon: 'icon-threads'
    },
    {
      href: 'https://www.instagram.com/starlingbank/',
      aria: 'on Instagram',
      icon: 'icon-instagram'
    },
    {
      href: 'https://www.linkedin.com/company/starlingbank',
      aria: 'on Linkedin',
      icon: 'icon-linkedin-squared'
    },
    {
      href: 'https://flipboard.com/@starlingbank/starling-bank-blog-rponqiocz',
      aria: 'on Flipboard',
      icon: 'icon-flipboard-white-footer',
      ds: 'flipboard'
    },
    {
      href: 'https://www.youtube.com/c/StarlingBankApp',
      aria: 'on Youtube',
      icon: 'icon-youtube-play'
    },
    {
      href: 'https://www.tiktok.com/@starlingbank',
      aria: 'on TikTok',
      icon: 'icon-tiktok'
    },
    {
      href: 'https://www.pinterest.co.uk/StarlingBank/',
      aria: 'on Pinterest',
      icon: 'icon-pinterest'
    },
    {
      href: '/rss.xml',
      aria: 'blog RSS feed',
      icon: 'icon-rss-white-footer',
      ds: 'rss'
    }
  ]
};

export const siteNavigation: SiteNavigation = {
  pca: {
    intro: ['Personal banking', 'Master your money with the Which? Recommended Current Account 2024'],
    footerLinks: {
      one: [
        { text: 'Switch to Starling', href: '/current-account/switch/' },
        { text: 'Read Noteworthy', href: '/blog/' }
      ],
      two: [{ text: 'About', href: '/about/' }],
      three: [{ text: 'Help', href: '/help/' }],
      four: []
    },
    productLinks: [
      { icon: 'iconPca', text: 'Personal account', href: '/current-account/' },
      {
        icon: 'iconJca',
        text: 'Joint account',
        href: '/current-account/joint-bank-account/'
      },
      {
        icon: 'iconKite',
        text: 'Starling Kite',
        href: '/current-account/kite-debit-card-for-kids/'
      },
      {
        icon: 'iconTeen',
        text: 'Teen account',
        href: '/current-account/bank-account-for-teenagers/'
      },
      {
        icon: 'iconEuro',
        text: 'Euro account',
        href: '/current-account/euro-bank-account/'
      }
    ],
    linkSections: [
      {
        heading: 'Manage',
        links: [
          { text: 'Spaces', href: '/features/spaces/' },
          { text: 'Round Ups', href: '/features/round-ups/' },
          { text: 'Virtual cards', href: '/features/virtual-cards/' },
          { text: 'Bills Manager', href: '/features/bills-manager/' },
          { text: 'Connected cards', href: '/features/connected-shopping-card/' },
          { text: 'Send money abroad', href: '/send-money-abroad/' }
        ]
      },
      {
        heading: 'Borrow',
        links: [
          { text: 'Personal overdraft', href: '/current-account/overdraft/' },
          { text: 'Overdraft eligibility tool', href: '/current-account/overdraft/overdraft-eligibility-tool/' },
          { text: 'Overdraft cost calculator', href: '/current-account/overdraft/overdraft-cost-calculator/' }
        ]
      },
      {
        heading: 'Save',
        links: [
          { text: 'Easy Saver', href: '/savings/easy-saver/' },
          { text: 'Fixed Saver', href: '/savings/fixed-rate-savings/' }
        ]
      },
      {
        heading: 'Tools',
        links: [
          { text: 'Budget planner', href: '/current-account/tools/budget-planner/' },
          { text: 'Online banking', href: '/online-banking/' },
          { text: 'Mobile cheque deposits', href: '/features/deposit-cheques-from-mobile/' },
          { text: 'Marketplace', href: '/current-account/personal-finance-marketplace/' }
        ]
      },
      {
        heading: 'More',
        links: [
          { text: 'No fees travel', href: '/travel/' },
          { text: 'Fees, rates and charges', href: '/legal/' },
          { text: 'Compare current accounts', href: '/current-account/compare-best-bank-accounts/' },
          { text: 'Apply for an account', href: '/open-bank-account-online/' },
          { text: 'Explore all features', href: '/features/' }
        ]
      }
    ]
  },
  bca: {
    intro: ['Business banking', 'Apply for a free digital bank account, and join over 450,000 UK businesses'],
    footerLinks: {
      one: [
        { text: 'Switch to Starling', href: '/business-account/switch-business-account/' },
        { text: 'Read Noteworthy', href: '/blog/' }
      ],
      two: [{ text: 'About', href: '/about/' }],
      three: [{ text: 'Help', href: '/help/' }],
      four: []
    },
    productLinks: [
      { icon: 'iconBca', text: 'Business account', href: '/business-account/' },
      { icon: 'iconSta', text: 'Sole trader account', href: '/sole-trader-bank-account/' },
      {
        icon: 'iconMultiCurrency',
        text: 'Multi-currency accounts',
        href: '/business-account/multi-currency/'
      }
    ],
    linkSections: [
      {
        heading: 'Manage',
        links: [
          { text: 'Business Toolkit', href: '/business-account/sme-business-toolkit/' },
          { text: 'Spaces', href: '/features/spaces/' },
          { text: 'Bills Manager', href: '/features/bills-manager/' },
          { text: 'Bulk Payments', href: '/features/bulk-payments/' },
          { text: 'Send money abroad', href: '/send-money-abroad/' }
        ]
      },
      {
        heading: 'Borrow',
        links: [
          { text: 'Business overdraft', href: '/business-account/overdraft/' },
          { text: 'Business loan', href: '/loans/business-loan/' },
          {
            text: 'Repaying your bounce back loan',
            href: '/business-account/business-bounce-back-loan-scheme/#repay'
          },
          {
            text: 'Pay as you grow ',
            href: '/business-account/business-bounce-back-loan-scheme/pay-as-you-grow-bounce-back-repayment-calculator/'
          }
        ]
      },
      {
        heading: 'Save',
        links: [{ text: 'Fixed Saver', href: '/business-account/business-fixed-rate-savings/' }]
      },
      {
        heading: 'Tools',
        links: [
          { text: 'Browse free business guides', href: '/resources/business-guides/' },
          { text: 'Mobile cheque deposits', href: '/features/deposit-cheques-from-mobile/' },
          { text: 'Online banking', href: '/online-banking/' },
          {
            text: 'Business marketplace',
            href: '/business-account/marketplace-for-businesses/'
          }
        ]
      },
      {
        heading: 'More',
        links: [
          { text: 'Business account eligibility', href: '/business-account/eligibility/' },
          { text: 'Compare business accounts', href: '/business-account/compare-business-bank-accounts/' },
          { text: 'Apply for an account', href: '/open-bank-account-online/' },
          { text: 'Fees, rates and charges', href: '/legal/' },
          { text: 'Explore all features', href: '/features/' }
        ]
      }
    ]
  },
  topRight: [
    {
      text: 'About',
      href: '#',
      children: [
        { text: 'About us', href: '/about/' },
        { text: 'Newsroom', href: '/media/' },
        { text: 'Sustainable banking', href: '/about/sustainable-banking/' },
        { text: 'Refer a friend', href: '/refer-a-friend/' },
        { text: 'Careers', href: '/careers/' }
      ]
    },
    { text: 'Help', href: '/help/' },
    { text: 'Log in', href: 'https://app.starlingbank.com/' }
  ]
};
